@media (min-width: 768px) {
    .animate {
      animation-duration: 0.3s;
      -webkit-animation-duration: 0.3s;
      animation-fill-mode: both;
      -webkit-animation-fill-mode: both;
    }
  }
  
  @keyframes slideIn {
    0% {
      transform: translateY(1rem);
      opacity: 0;
    }
  
    100% {
      transform: translateY(0rem);
      opacity: 1;
    }
  
    0% {
      transform: translateY(1rem);
      opacity: 0;
    }
  }
  
  @-webkit-keyframes slideIn {
    0% {
      -webkit-transform: transform;
      -webkit-opacity: 0;
    }
  
    100% {
      -webkit-transform: translateY(0);
      -webkit-opacity: 1;
    }
  
    0% {
      -webkit-transform: translateY(1rem);
      -webkit-opacity: 0;
    }
  }
  
  .slideIn{
    -webkit-animation-name: slideIn;
    animation-name: slideIn;
  }